var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-content__canvas-selector"},[(_vm.selected && !_vm.isBlocked)?_c('div',{ref:"resizeOut",staticClass:"post-content__resize-tools",class:{'pref': _vm.selectedImage},style:(_vm.styles)},[_c('div',{staticClass:"post-content__resize-tl",on:{"pointerdown":function($event){return _vm.resize($event, 'top-left')}}}),(_vm.selectedImage.typeItem == 'text' && _vm.selectedImage.width > 120)?_c('div',{staticClass:"post-content__resize-cl",on:{"pointerdown":function($event){return _vm.resize($event, 'center-left')}}}):_vm._e(),(_vm.selectedImage.typeItem == 'text' && _vm.selectedImage.width > 120 || _vm.selectedImage.typeItem != 'text')?_c('div',{staticClass:"post-content__resize-tr",on:{"pointerdown":function($event){return _vm.resize($event, 'top-right')}}}):_vm._e(),(_vm.selectedImage.typeItem == 'text' && _vm.selectedImage.width > 120 || _vm.selectedImage.typeItem != 'text')?_c('div',{staticClass:"post-content__resize-bl",on:{"pointerdown":function($event){return _vm.resize($event, 'bottom-left')}}}):_vm._e(),(_vm.selectedImage.typeItem == 'text')?_c('div',{staticClass:"post-content__resize-cr",on:{"pointerdown":function($event){return _vm.resize($event, 'center-right')}}}):_vm._e(),(_vm.selectedImage.typeItem == 'text' && _vm.selectedImage.width > 120 || _vm.selectedImage.typeItem != 'text')?_c('div',{staticClass:"post-content__resize-br",on:{"pointerdown":function($event){return _vm.resize($event, 'bottom-right')}}}):_vm._e(),_c('div',{staticClass:"post-content__resize-box",on:{"pointerdown":function($event){return _vm.movePosition($event)}}}),(_vm.selectedImage.typeItem == 'text')?_c('div',{class:{ opacity_0 : _vm.quillEditor == null || _vm.isTextResize == true },style:({
          position: 'relative',
          transformOrigin: '0 0',
          transform: `scale(${_vm.zoom.transform})`,
          width: `${_vm.selectedImage.width}px`,
          // width: `max-content`,
          height: `auto`,
          // top: '3px',
          // left: '3px',
          // overflow: 'hidden',
        }),attrs:{"id":"wrapQuill"}},[_c('div',{style:({
            width: `${_vm.selectedImage.width}px`,
            // height: `${selectedImage.height - 10}px`,
          }),attrs:{"id":"editorQuill"}})]):_vm._e(),_c('div',{staticClass:"post-content__rotate",on:{"pointerdown":_vm.rotation}},[_c('v-icon',{attrs:{"size":"16","color":"#00a5ff"}},[_vm._v("$loading")])],1)]):_vm._e(),(_vm.isHoverStyles)?_c('div',{staticClass:"post-content__resize-tools",style:(_vm.selectedHoverStyles)},[_c('div',{staticClass:"post-content__resize-box",on:{"mouseout":function($event){return _vm.onMouseOut($event)},"pointerdown":function($event){_vm.onClick($event); _vm.movePosition($event)},"click":function($event){return _vm.onClick($event)}}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }