<template>
  <div class="post">
    <div v-if="isScreensizeLess" class="d-flex justify-center align-center" style="height: 100%; background-color: #00a5ff;">
      <screensize-modal/>
    </div>
    <div class="post__main" v-if="!isScreensizeLess">
      <aside class="aside__content">
        <div id="aside" class="aside__menu">
          <div class="sidebar">
            <div class="sidebar_menu">
              <div class="menu__aside"></div>
              <sidebar-module />
              <menu-module />
            </div>
          </div>
        </div>
      </aside>
      <div class="lienzo">
        <main class="lienzo__main">
          <div class="main_wrapper">
            <header-module />
            <content-module @capture="captureData" ref="captureRef" />
          </div>
          <side-controls
            v-if="selected && !isCrop"
            class="side-controls"
          ></side-controls>
        </main>
        <!-- clientId comes from HandleRespMixin -->
        <footer-module
          @id-folder="folderId"
          :post-loading="saveLoading"
          @update-post="updatePost($event)"
          :postName="namePost"
					:clientId="clientId"
          @send-success-msg="successModal" 
          @send-error-msg="errorModal"
        />
      </div>
    </div>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      @input="closeIconX"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
    />
  </div>
</template>

<script>
import SidebarModule from "@/components/modules/sidebar/SidebarModule.vue";
import HeaderModule from "@/components/modules/header/HeaderModule.vue";
import MenuModule from "@/components/modules/menu/MenuModule.vue";
import FooterModule from "@/components/modules/footer/FooterModule.vue";
import ContentModule from "@/components/modules/content/ContentModule.vue";
import SideControls from "@/components/controls/SideControls";
import { HtmlToImage, ScreensizeModal, HandleRespMixin, IscModalAlert } from "@design/styleguide";


export default {
  components: {
    SidebarModule,
  	HeaderModule,
    ContentModule,
    SideControls,
    FooterModule,
    MenuModule,
    ScreensizeModal,
    IscModalAlert
  },
  mixins: [ HandleRespMixin],
  data() {
    return {
      saveLoading: false,
      idFolder: 0,
      refCapture: null,
      isScreensizeLess: false,
    };
  },
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    namePost() {
      return this.$store.getters["post/main/name"];
    },
    images() {
      return this.$store.getters["post/drag/images"];
    },
    backgroundBg() {
      return this.$store.getters["post/background/bg"].background;
    },
    background(){
      return this.$store.getters["post/background/background"];
    },
    postEditIdFolder() {
      return this.$store.getters["post/main/folderId"];
    },
    size() {
      return this.$store.getters["post/zoom/post"];
    },
    isCrop() {
      return this.$store.getters["post/navControls/isCrop"];
    },
    isHover() {
      return this.$store.getters["post/drag/hover"];
    },
  },
  mounted() {
    this.$store.dispatch("post/GET_MENU_ACTIVE", { name: "Templates" });
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);    
  },
  methods: {
    captureData(val) {
      this.refCapture = val;
    },
    folderId(val) {
      this.idFolder = val;
      if (this.idFolder <= 0) return;
      this.savePost();
    },
    async savePost() {
      const isName = this.validateName();
      const isClientId = this.validateClientId(this.clientId);
      if (!isName) return;
      if (!isClientId) return;
      try {
        this.$store.commit("global/saved/SET_LOADING", {
          load: true,
          status: false,
        });
        const finalImage = await this.getUrlFinalImage();
        const post = this.dataPost(this.idFolder, finalImage, this.clientId);
        this.$store.dispatch("post/saved/CREATE", { post, responseFunc: this.responseMessageGraphql }); // callback error GraphQl(responseFunc)
      } catch (error) {
        console.log(" error ", error);
        this.$store.commit("global/saved/SET_LOADING", {
          load: false,
          status: false,
        });
        this.invalidateMessage("Something went wrong!");
      }
    },
    async updatePost(id) {
      const isName = this.validateName();
      const isClientId = this.validateClientId(this.clientId);
      if (!isName) return;
      if (!isClientId) return;
      try {
        this.$store.commit("global/saved/SET_LOADING", {
          load: true,
          status: false,
        });
        const finalImage = await this.getUrlFinalImage();
        const post = this.dataPost(this.postEditIdFolder, finalImage, this.clientId);
        this.$store.dispatch("post/saved/UPDATE", { id, post, responseFunc: this.responseMessageGraphql }); // callback error GraphQl(responseFunc)
      } catch (error) {
        console.log(" error ", error);
        this.$store.commit("global/saved/SET_LOADING", {
          load: false,
          status: false,
        });
        this.invalidateMessage("Something went wrong!");
      }
    },
    dataPost(idFolder, finalImage, clientId) {
      const data = {
        clientId,
        name: this.namePost,
        _position: 3,
        folderId: idFolder,
        width: this.size.width.toString(),
        height: this.size.height.toString(),
        dataObjects: {
          post: {
            drag: {
              images: this.images,
            },
            background: {
              background: !this.background ? this.backgroundBg : this.background,
            },
          },
        },
        finalImage,
        typeUser: "user",
      };
      return data;
    },
    //* TAKING A SCREEN SHOOT AND UPLOAD TO SERVER
    async getUrlFinalImage() {
      this.$refs.captureRef.emitRefCapture();
      const capture = this.refCapture;
      const file = await this.convertToJpegAndFile(capture);
      return await this.uploadImage(file);
    },
    async convertToJpegAndFile(capture) {
      const dataUrl = await HtmlToImage.toBlob(capture, { 
				quality: 0.95,
				canvasWidth: this.size.width.toString(),
				canvasHeight: this.size.height.toString(),
			});
      // convert base 64 to File
      const file = new File([dataUrl], "photo_finalImage_post.png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      });
      return file;
    },
    async uploadImage(file) {
      let formData = new FormData();
      formData.append("file", file);
      const {
        data: {
          file: { path },
        },
      } = await this.$store.dispatch("post/uploads/UPLOAD_SINGLE_IMAGE", {
        formData,
      });
      return path;
    },
    //*VALIDATIONS
    validateName() {
      if (!this.namePost) {
        this.invalidateMessage("It is required Post's name");
        return false;
      } else return true;
    },
    validateClientId(id) {
      if (!id) {
        this.invalidateMessage("Client does not exist.");
        return false;
      } else return true;
    },
    invalidateMessage(text) {
      this.errorModal(text)
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      (this.width < 1024) ? this.isScreensizeLess = true : this.isScreensizeLess = false;
    },
  },
  destroyed() {
    this.$store.dispatch("post/main/RESET_POST");
    this.$store.dispatch("global/saved/ENABLE_EDIT_PROJECT", false);
  },
};
</script>

<style scoped lang="scss">
@import "./Post";
</style>
