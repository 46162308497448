<template>
  <div class="post__menu pt-3">
    <div class="post__menu_content">
      <div class="content">
        <div class="content_wrapper">
          <div class="content_wrapper_list">
            <div class="wrapper_image">
              <div class="wrapper_image__content">
                <div class="image__column">
                  <div :class="moduleName !== 'uploads' ? 'mb-3' : 'mb-2'">
                    <v-row class="align-center" dense>
                      <v-col
                        class="text-left"
                        :class="moduleName === 'uploads' ? 'post' : 'pa-0'"
                        cols="6"
                        style="padding: 1rem 0rem !important"
                      >
                        <router-link
                          :to="{ path: '/main' }"
                          class="white--text text-decoration-none d-flex"
                        >
                          <v-icon size="12">$arrow-right</v-icon>
                          <span class="post_name">Post</span>
                        </router-link>
                      </v-col>
                      <v-col cols="6" class="pa-0" v-if="shouldDisplay">
                        <search-input
                          :type="moduleName.toLowerCase()"
                          @trigger-find="triggerFind"
                        />
                      </v-col>
                      <v-col v-else cols="6" class="pr-3 text-right">
                        <template v-if="moduleName === 'uploads'">
                          <btn-input-files
                            :multiple="true"
                            @files="saveFiles"
                            :uploading="loading"
                            :progress="progress"
                            @msg-error="errorModal"
                            :max="8"
                          />
                        </template>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    style="height: 100%"
                    :class="{ 'scroll-base': moduleName == 'shapes' }"
                  >
                    <post-templates
                      v-if="active === 'Templates'"
                      :trigger="value"
                    />
                    <post-images v-if="active === 'Images'" :trigger="value" />
                    <post-background
                      v-if="active === 'Background'"
                      :trigger="value"
                    />
                    <post-shapes v-if="active === 'Shapes'" :trigger="value" />
                    <post-text v-if="active === 'Texts'" :trigger="value" />
                    <post-uploads
                      v-if="active === 'Uploads'"
                      :files="files"
                      @uploading="loadFiles"
                      @progress-upload="progressUpload"
                    />
                    <post-saved v-if="active === 'Saved'" :trigger="value" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
      @clicked="resultModalAlerts"
    />
  </div>
</template>

<script>
import PostTemplates from "../../../views/post/01-Templates/Templates";
import PostImages from "../../../views/post/02-Images/Images";
import PostBackground from "../../../views/post/03-Background/Background";
import PostShapes from "../../../views/post/04-Shapes/Shapes";
import PostText from "../../../views/post/05-Text/Text";
import PostUploads from "../../../views/post/06-Uploads/Uploads";
import PostSaved from "../../../views/post/07-Saved/Saved";
import BtnInputFiles from "../../buttons/BtnInputFiles";
import SearchInput from "../../inputs/SearchInput";
import { IscModalAlert, AlertSetting } from "@design/styleguide";
export default {
  name: "PostMenu",
  mixins: [AlertSetting],
  components: {
    PostSaved,
    PostUploads,
    PostText,
    PostShapes,
    PostBackground,
    PostImages,
    PostTemplates,
    SearchInput,
    BtnInputFiles,
    IscModalAlert,
  },
  data() {
    return {
      value: null,
      moduleName: "Templates",
      files: [],
      loading: false,
      progress: 0,
    };
  },
  computed: {
    active() {
      return this.$store.getters["post/menuActive"];
    },
    shouldDisplay() {
      if (this.moduleName === "saved") return false;
      else if (this.moduleName === "uploads") return false;
      else return true;
    },
  },
  watch: {
    active(value) {
      this.moduleName = value.toLowerCase();
    },
  },
  methods: {
    triggerFind(val) {
      this.value = val;
    },
    saveFiles(val) {
      this.files = val;
    },
    loadFiles(val) {
      this.loading = val;
    },
    progressUpload(val) {
      this.progress = val;
    },
  },
};
</script>

<style scoped lang="scss" src="./MenuModule.scss"></style>
