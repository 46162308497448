<template>
  <div>
    <v-list-group
      v-if="item.children !== undefined && item.children.length"
      v-model="item.active"
    >
    </v-list-group>
    <v-list-item 
			v-else 
			link 
			router 
			class="menu"
			@click="activeMenu(item.name)"
		>
      <div class="menu_content">
        <v-list-item-icon class="ma-0">
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item-content>
      </div>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: "SidebarSubMenu",
  props: ["item"],
	methods: {
    activeMenu(name) {
      this.$store.dispatch('post/GET_MENU_ACTIVE', {name});
    }
  },
};
</script>
<style scoped lang="scss">
.menu {
  display: block;
  text-align: center;
	justify-content: center;
  padding: 1.5rem 0 !important;
  height: 100%;
  &_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    .v-list-item__icon {
      width: 100%;
      display: block;
    }
  }

  .v-list-item__title {
    color: #96A0B8;
    font-size: 10px !important;
  }
}
</style>
