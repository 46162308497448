<template>
  <div
    v-if="addTemplates.length > 0"
    class="image-horizontal__content wrapper-scroll scroll-base container_image_scroll"
    v-on:scroll="handleScroll"
    ref="container"
  >
    <div
      class="img_wrapper"
      v-for="template in addTemplates"
      :key="template.uuid"
      @click="chooseTemplate(template)"
    >
      <v-img
        :src="template.finalImage"
        aspect-ratio="1"
        :alt="template.name"
        style="margin: 4px; cursor: pointer"
      />
    </div>
  </div>
  <div
    v-else-if="templatesByKeywords.length > 0"
    class="image-horizontal__content wrapper-scroll scroll-base container_image_scroll"
  >
    <div
      class="img_wrapper"
      v-for="template in templatesByKeywords"
      :key="template.uuid"
      @click="chooseTemplate(template)"
    >
      <v-img
        :src="template.finalImage"
        aspect-ratio="1"
        :alt="template.name"
        style="margin: 4px; cursor: pointer"
      />
    </div>
  </div>
  <div v-else class="loader-container">
    <div v-if="activeScroll" class="loader-container__progress">
      <div class="loader-container__progress--text">Getting Templates</div>
      <v-progress-linear
        color="white"
        height="6"
        indeterminate
        rounded
      ></v-progress-linear>
    </div>
    <div v-else class="loader-container__progress">
      <div class="loader-container__progress--text">
        Sorry, we couldn't find any matching items
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  name: "PostTemplate",
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base_url_images_thumb:
        process.env.BASE_URL_IMAGES_THUMBS ||
        "https://d2wk4yjqh165z7.cloudfront.net/fit-in/0x154/img",
      take: 15,
      page: 1,
      addTemplates: [],
      loadingScroll: false,
      firstAmountOfTemplates: 0,
      activeScroll: true,
      image: null,
    };
  },
  created() {
    this.$store.dispatch("post/templates/FIND", {
      take: this.take,
      page: this.page,
    });
  },
  computed: {
    templates() {
      return this.$store.getters["post/templates/templates"];
    },
    templatesByKeywords() {
      return this.$store.getters["post/templates/templatesByKeywords"];
    },
  },
  watch: {
    templates(templates) {
      if (templates.length > 0) {
        templates.forEach((el) => {
          this.addTemplates.push(el);
        });
      }
    },
    trigger(value) {
      if (value) this.activeScroll = true;
      else {
        this.$store.commit("post/templates/RESET_TEMPLATES_BY_KEYWORDS");
        this.activeScroll = false;
        this.resetVariablesScroll();
      }
    },
  },
  methods: {
    handleScroll() {
      /* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
      const { scrollHeight, scrollTop, clientHeight } = this.$refs.container;
      const entireHeight = Math.round(scrollTop + clientHeight);
      this.loadingScroll = false;

      if (entireHeight >= scrollHeight && !this.loadingScroll) {
        const validationLengthTemplates = this.validationMoreTemplates();
        if (!validationLengthTemplates) return;
        this.addItem();
        this.loadingScroll = true;
      }
    },
    addItem() {
      this.page++;
      this.$store.dispatch("post/templates/FIND", {
        take: this.take,
        page: this.page,
      });
    },
    validationMoreTemplates() {
      if (this.firstAmountOfTemplates === 0) {
        this.firstAmountOfTemplates = this.addTemplates.length;
        return true;
      }
      if (this.firstAmountOfTemplates < this.addTemplates.length) {
        this.firstAmountOfTemplates = this.addTemplates.length;
        return true;
      } else return false;
    },
    chooseTemplate(template) {
      delete template.id;
      this.$store.dispatch("post/main/LOAD_POST_CANVAS", template);
    },
    //* Reset variables to default values when the user leave keyword searching
    resetVariablesScroll() {
      this.addTemplates = [];
      this.firstAmountOfTemplates = 0;
      this.page = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.img_wrapper {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  img {
    margin: 4px;
    cursor: pointer;
  }
}
</style>
<!-- Sharing the same style to other modules, no scope -->
<style lang="scss">
.loader-container {
  height: 25vh; /* Set the height of the container to the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__progress {
    &--text {
      margin: 10px;
      font-size: 15px;
    }
  }
}
</style>
