<template>
  <div>
    <div class="d-flex align-center">
      <div class="toolQuill"></div>
      <custom-select
        :options="fonts"
        :default="selectedText.fontFamily"
        class="select mr-2 bsss"
        @input="setFontFamily($event)"
      ></custom-select>
      <div class="relative d-flex">
        <custom-input-number
          :default="fontSizeValue"
          class="select mr-2"
          @input="setFontSize($event)"
        ></custom-input-number>
        <div class="d-flex flex-column btn_fontSizes_action absolute">
          <button class="btn" @click="setFontSize($event, 'up')">
            <v-icon> mdi-menu-up </v-icon>
          </button>
          <button @click="setFontSize($event, 'down')">
            <v-icon> mdi-menu-down </v-icon>
          </button>
        </div>
        <div class="no-fontsize-value" v-if="showFontSizeText">--</div>
      </div>

      <!-- <custom-color-picker ></custom-color-picker> -->

      <v-menu offset-y :close-on-content-click="false" v-model="isColorPickerOpen">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="d-flex align-center me-3">
            <div
              class="button-color"
              :style="{ background: color_Selected }"
            ></div>
          </div>
        </template>
        <div class="white pa-3">
          <isc-color-picker
            @input="setColor($event)"
            :value="color_Selected"
          ></isc-color-picker>
        </div>
      </v-menu>

      <v-btn
        class="mr-3"
        icon
        tile
        @click="setFontWeight"
        :color="textMenuAttributes.bold === true ? 'blue' : null"
      >
        <v-icon dark> mdi mdi-format-bold </v-icon>
      </v-btn>
      <v-btn
        class="mr-3"
        icon
        tile
        @click="setFontCursive"
        :color="textMenuAttributes.italic === true ? 'blue' : null"
      >
        <v-icon dark> mdi mdi-format-italic </v-icon>
      </v-btn>

      <v-btn
        class="mr-3"
        icon
        tile
        @click="setFontUnderline"
        :color="textMenuAttributes.underline === true ? 'blue' : null"
      >
        <v-icon dark> mdi mdi-format-underline </v-icon>
      </v-btn>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-3"
            icon
            tile
            v-bind="attrs"
            v-on="on"
            :color="
              textMenuAttributes.align && isActiveToTextEdit ? 'blue' : null
            "
          >
            <v-icon dark>{{
              `mdi mdi-format-align-${textMenuAttributes.align || "center"}`
            }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in ['left', 'right', 'center', 'justify']"
            :key="i"
            class="list-interhight overflow-hidden"
            :style="{
              background:
                item == textMenuAttributes.align ? '#00a5ff' : 'black',
            }"
            @click="setFontAlign(item)"
          >
            <v-icon dark class="">{{ `mdi mdi-format-align-${item}` }}</v-icon>
            <!-- <v-list-item-title>{{ item }}</v-list-item-title> -->
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn class="mr-3" icon tile @click="setFontTransform">
        <span
          class="upp_bt overflow-hidden d-inline-block"
          :class="{
            opacity__add: textMenuAttributes.textTransform === 'lowercase',
          }"
        >
          <v-icon
            dark
            :color="
              textMenuAttributes.textTransform === 'uppercase' ? 'blue' : null
            "
          >
            mdi mdi-format-letter-case-upper
          </v-icon>
        </span>
        <span
          class="low_bt overflow-hidden d-inline-block"
          :class="{
            opacity__add: textMenuAttributes.textTransform === 'uppercase',
          }"
        >
          <v-icon
            dark
            :color="
              textMenuAttributes.textTransform === 'lowercase' ? 'blue' : null
            "
          >
            mdi mdi-format-letter-case-upper
          </v-icon>
        </span>
      </v-btn>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-3"
            icon
            tile
            v-bind="attrs"
            v-on="on"
            :color="
              textMenuAttributes.textLineHeight &&
              textMenuAttributes.textLineHeight != 'none'
                ? 'blue'
                : null
            "
          >
            <v-icon dark> mdi mdi-format-line-spacing </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in ['1-0', '1-25', '1-5', '2-0', '2-5', '3-0']"
            :key="i"
            class="list-interhight"
            v-bind:style="{
              background:
                textMenuAttributes.textLineHeight === item
                  ? '#00a5ff'
                  : 'black',
            }"
            @click="setInterHight(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-btn class="mr-3" icon tile>
        <v-icon dark> mdi mdi-format-line-spacing </v-icon>
      </v-btn> -->

      <v-btn icon tile @click="setListStyle">
        <v-icon v-if="btn_list_default" dark
          >mdi mdi-format-list-bulleted-square</v-icon
        >
        <v-icon :color="'blue'" v-if="textMenuAttributes.list == 'bullet'"
          >mdi mdi-format-list-bulleted</v-icon
        >
        <v-icon :color="'blue'" v-if="textMenuAttributes.list == 'ordered'" dark
          >mdi mdi-format-list-numbered</v-icon
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
import { IscColorPicker } from "@design/styleguide";
import CustomSelect from "@/components/textTools/CustomSelect";
import CustomInputNumber from "@/components/textTools/CustomInputNumber";
import CustomColorPicker from "@/components/textTools/CustomColorPicker";
import { quillFunctions } from "@design/styleguide";
export default {
  name: "TextControls",
  components: {
    IscColorPicker,
    CustomSelect,
    CustomInputNumber,
    CustomColorPicker,
  },
  data() {
    return {
      newParagraphsList:[],
      isColorPickerOpen:false,
      btn_list_default: true,
      old_paragraphList: null,
      color_Selected: "#000000",
      textMenuAttributes: {},
      showFontSizeText: false,
      isBold: false,
      fonts: [
        { value: "1", text: "Andada", font: "AndadaPro" },
        { value: "2", text: "Arial", font: "Arial" },
        { value: "3", text: "Antonio", font: "Antonio" },
        { value: "4", text: "Arapey", font: "Arapey" },
        { value: "5", text: "Archivo", font: "Archivo" },
        { value: "6", text: "Assistant", font: "Assistant" },
        { value: "7", text: "Barlow", font: "Barlow" },
        { value: "8", text: "Basic", font: "Basic" },
        { value: "9", text: "Be Vietnam", font: "BeVietnam" },
        { value: "10", text: "Bilbo", font: "Bilbo" },
        { value: "11", text: "Black Han Sans", font: "BlackHanSans" },
        { value: "12", text: "Blinker", font: "Blinker" },
        { value: "13", text: "Boogaloo", font: "Boogaloo" },
        { value: "14", text: "Bowlby One", font: "BowlbyOne" },
        { value: "15", text: "Bubbler One", font: "BubblerOne" },
        { value: "16", text: "Bungee", font: "Bungee" },
        { value: "17", text: "Bebas Neue", font: "BebasNeue" },
        { value: "18", text: "Economica", font: "Economica" },
        { value: "19", text: "Baskerville", font: "Baskerville" },
        { value: "20", text: "Seaweed Script", font: "SeaweedScript" },
        { value: "21", text: "Roboto", font: "Roboto" },
        { value: "22", text: "Phosphate", font: "Phosphate" },
        { value: "23", text: "Abhaya Libre", font: "AbhayaLibre" },
      ],
      brightness: 1,
      contrast: 1,
      saturate: 1,
      temperature: 1,
      blur: 0,
      fontSizeValue: 0,
      ifFontSizeStageIncrease: true,
      fontSizeStageValue: 0,
      fontSizeStageValueObj: null,
      fontSizeValueNew: 0,
      fontFamilyValue: null,
      masterFilter: "",
      imgTrans: "",
      flipVert: false,
      flipHori: false,
      cropFlag: false,
      cropEdit: false,
      colorPickerActive2: false,
      item: "100%",
      items: ["25%", "50%", "80%", "100%", "125%", "150%", "200%"],
      type: "hexa",
      hexa: "#000000FF",
      elementos: [
        { value: "1", text: "Andada", font: "Andada" },
        { value: "2", text: "Anton", font: "Anton" },
        { value: "3", text: "Antonio", font: "Antonio" },
        { value: "4", text: "Arapey", font: "Arapey" },
        { value: "5", text: "Archivo", font: "Archivo" },
        { value: "6", text: "Assistant", font: "Assistant" },
        { value: "7", text: "Barlow", font: "Barlow" },
        { value: "8", text: "Basic", font: "Basic" },
        { value: "9", text: "Be Vietnam", font: "Be Vietnam" },
        { value: "10", text: "Bilbo", font: "Bilbo" },
        { value: "11", text: "Black Han Sans", font: "Black Han Sans" },
        { value: "12", text: "Blinker", font: "Blinker" },
        { value: "13", text: "Boogaloo", font: "Boogaloo" },
        { value: "14", text: "Bowlby One", font: "Bowlby One" },
        { value: "15", text: "Bubbler One", font: "Bubbler One" },
        { value: "16", text: "Bungee", font: "Bungee" },
        { value: "17", text: "Economica", font: "Economica" },
        { value: "18", text: "Baskerville", font: "Baskerville" },
        { value: "19", text: "Roboto", font: "Roboto" },
        { value: "20", text: "Phosphate", font: "Phosphate" },
        { value: "21", text: "Abhaya Libre", font: "AbhayaLibre" },
      ],
      lastSelection: null,
      newSelection: null,
      selectedItemStyles: null,
    };
  },
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    selectedDetail() {
      return this.$store.getters["post/image/selectedDetail"];
    },
    element() {
      return this.$store.getters["post/rect/element"];
    },
    fontWeightD() {
      return this.$store.getters["post/texts/fontWeight"];
    },
    fontCursiveD() {
      return this.$store.getters["post/texts/fontCursive"];
    },
    fontUnderlineD() {
      return this.$store.getters["post/texts/fontUnderline"];
    },
    fontAlignCD() {
      return this.$store.getters["post/texts/fontAlignC"];
    },
    fontAlignLD() {
      return this.$store.getters["post/texts/fontAlignL"];
    },
    fontAlignRD() {
      return this.$store.getters["post/texts/fontAlignR"];
    },
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      let newColor = this.color;
      this.$store.commit("post/texts/SET_FONT_COLOR", newColor);
      return "";
    },
    selectedText() {
      let selectTextValue = this.$store.getters["post/drag/selectedText"];
      if (selectTextValue != null) {
        const {
          styles: { fontWeight, fontSize, fontFamily, color },
          key,
        } = selectTextValue;

        return {
          fontSize: parseFloat(fontSize.replace("px", "")),
          fontWeight,
          fontFamily,
          color: color == "#000" ? "#000000FF" : color,
          key,
        };
      }
    },
    allImages() {
      return this.$store.getters["post/drag/images"];
    },
    quillEditor() {
      return this.$store.getters["post/drag/quillEditor"];
    },
    quillGetSelection() {
      return this.$store.getters["post/drag/quillGetSelection"];
    },
    quillGetLastSelection() {
      return this.$store.getters["post/drag/quillGetLastSelection"];
    },
    fontSizeList() {
      return this.$store.getters["post/drag/fontSizeList"];
    },
    isActiveToTextEdit() {
      return this.$store.getters["post/drag/isActiveToTextEdit"];
    },
    showFontSizeTextEl() {
      return this.$store.getters["post/drag/showFontSizeText"];
    },
    paragraphsList() {
      return this.$store.getters["post/drag/paragraphsList"];
    },
  },
  watch: {
    paragraphsList(newValue, oldValue){
      if(this.selectedDetail.styles.fontsList == undefined) this.selectedDetail.styles.fontsList = [];
      newValue.forEach((item, index) => {
        if (!this.selectedDetail.styles.fontsList.some(newItem => newItem.index === item.index)) {
          this.selectedDetail.styles.fontsList.push({index:index,font:item.attributes.size,fontFamily:item.attributes.font || 'Arial'});
        }
      });
      this.selectedDetail.styles.fontsList = this.selectedDetail.styles.fontsList.filter(newItem => 
        newValue.some(item => item.index === newItem.index)
      );
      this.$store.dispatch("post/textControls/CHANGE_FONT_SIZE_IN_TEXT", {
        fontSize: this.selectedDetail.styles.fontsList,
        uuid: this.selected,
        index: this.selectedText.key,
      });
      // console.log('agrega nuevo parrafo',this.selectedDetail.styles.fontsList);
    },
    isColorPickerOpen(newValue, oldValue){
      this.returnSelectionRange().index == 0 && this.returnSelectionRange().length == this.quillEditor.getLength()
        ? this.quillEditor.setSelection(this.returnSelectionRange().length, 0)
        : this.quillEditor.setSelection(
            this.returnSelectionRange().index,
            this.returnSelectionRange().length
          );
      // console.log('is open menu', this.returnSelectionRange());
    },
    fontSizeStageValue(newValue, oldValue) {
      this.fontSizeStageValueObj = { new: newValue, old: oldValue };
      console.log("addd stage value", { new: newValue, old: oldValue });
    },
    selectedText(newValue, oldValue) {
      let siz = 0;
      fontFml = null;
      let paragraphList = this.quillEditor.container.firstChild.childNodes;
      siz = newValue.fontSize;
      let fontFml = newValue.fontFamily;
      // this.fontFamilyValue = fontFml;
      // if (
      //   paragraphList.length > 1 ||
      //   newValue == undefined ||
      //   newValue == null
      // ) {
      //   this.fontSizeValue = 0;
      //   this.showFontSizeText = true;
      //   console.log("selectedtext---- A", this.paragraphsList.length);
      // } else {
      //   console.log("selectedtext---- B", this.paragraphsList.length);
      //   this.showFontSizeText = false;
      //   this.fontSizeValue = siz;
      // }
    },
    isActiveToTextEdit(newValue, oldValue) {
      // console.log("actuve to edit", newValue);
      if (newValue) {
        let paragraphList = this.quillEditor.container.firstChild.childNodes;
        const parentItemIndex = this.allImages.findIndex(
          ({ uuid }) => uuid == this.selectedDetail.uuid
        );
      }
    },
    quillGetSelection(new_, old) {
      let index_ = null;
      let length_ = null;
      if (new_ != null) {
        index_ = new_.index;
        length_ = new_.length;
      }
      if (new_ == null) {
        index_ = old.index;
        length_ = old.length;
      }
      if (new_ != null && old != null) {
        index_ = new_.index;
        length_ = new_.length;
      }
      let formato = this.quillEditor.getFormat({
        index: index_,
        length: length_,
      });
      let sizeFormat = null;
      this.$store.dispatch("post/drag/ADD_QUILL_LAST_SELECTION", old);
      this.$store.dispatch("post/drag/ADD_QUILL_NEW_SELECTION", new_);
      this.lastSelection = old;
      this.newSelection = new_;
      this.returnSelectionRange();
      let deltaTrue = this.paragraphsList.filter((p) => p.change == true);
      let deltaFalse = this.paragraphsList.filter((p) => p.change == false);
      if (deltaTrue.length == 1) {
        this.$store.dispatch("post/drag/CHANGE_SHOWFONTSIZE_TEXT", false);
        this.showFontSizeText = false;
        if(deltaTrue[0].groups){
          let elementFontSizeGroup = deltaTrue[0].groups[0].attributes.size;
          elementFontSizeGroup.replace("px", "")
        }else{
          this.$store.dispatch("post/drag/ADD_LAST_ATTRIBUTES_SELECTED", deltaTrue[0]);
          if(deltaTrue[0].insert && deltaTrue[0].insert.length == 1 && deltaTrue[0].brInsert == null) this.quillEditor.setSelection(deltaTrue[0].end, 0);
          let elementFontSize = null;
          elementFontSize = deltaTrue[0].attributes.size;
          // deltaTrue[0].attributes.size;//)? '24px': deltaTrue[0].attributes.size;
          if(elementFontSize == undefined) {
            elementFontSize = '24px';
          }
          const fontSizeFilter = this.selectedDetail.styles.fontsList.find((el)=> el.index == deltaTrue[0].index);
          let isArray = Array.isArray(elementFontSize);
          if(isArray) elementFontSize = deltaTrue[0].attributes.size[0].replace("px", "");
          if(fontSizeFilter.font == undefined) fontSizeFilter.font = '24px';
          // sizeFormat = parseFloat(fontSizeFilter.font.replace("px", ""));
          if(Array.isArray(fontSizeFilter.font)) sizeFormat = parseFloat(fontSizeFilter.font[0].replace("px", ""));
          else sizeFormat = parseFloat(fontSizeFilter.font.replace("px", ""));
          // console.log('---------------...', sizeFormat);
          this.fontSizeValue = sizeFormat;
        }
      }
       else {
        // console.log('---------------...', deltaTrue);
        this.$store.dispatch("post/drag/CHANGE_SHOWFONTSIZE_TEXT", true);
        sizeFormat = 0;
        this.showFontSizeText = true;
      }
    },
    selectedDetail(newVal, oldVal) {
      if (newVal.typeItem == "text") {
        this.color_Selected = newVal.styles.color;
      }
    }
  },
  created() {
    this.$store.dispatch("post/image/UPDATE_IMAGE_SELECTED", this.selected);
    this.showFontSizeText = this.showFontSizeTextEl;
  },
  methods: {
    returnSelectionRange() {
      let selectionToAdd = null;
      const contentText = this.quillEditor.getText();
      if (this.newSelection == null && this.lastSelection == null) {
        selectionToAdd = {
          index: 0,
          length: this.quillEditor.getLength(),
        };
        // console.log('rango a retornar -1 ', {old:this.lastSelection, ne:this.newSelection,range:selectionToAdd});
      }

      if (this.newSelection !== null && this.lastSelection !== null) {
        selectionToAdd = this.newSelection;
        // console.log('rango a retornar -2 ', {old:this.lastSelection, ne:this.newSelection,range:selectionToAdd});
      }
      if (this.newSelection == null && this.lastSelection !== null) {
        selectionToAdd = this.lastSelection;
        // console.log('rango a retornar -3 ', {old:this.lastSelection, ne:this.newSelection,range:selectionToAdd});
      }
      if (this.newSelection !== null && this.lastSelection == null) {
        selectionToAdd = this.newSelection;
        // console.log('rango a retornar -4 ', {old:this.lastSelection, ne:this.newSelection,range:selectionToAdd});
      }
      this.newParagraphsList = [];
      
      // console.log('seleCTION----', selectionToAdd);
      // console.log('selection processed before', this.paragraphsList);
      selectionToAdd.itemParent = this.selectedDetail;
      let nValue = 0;
      for (let index = 0; index < this.paragraphsList.length; index++) {
        const element = this.paragraphsList[index];
        element.change = false;
        if (selectionToAdd.length == 0) {
          if (selectionToAdd.index >= element.start && selectionToAdd.index <= element.end){
            element.change = true;
          }
        } else {
          element.start < selectionToAdd.index + selectionToAdd.length &&
          element.end > selectionToAdd.index
            ? (element.change = true)
            : (element.change = false);
        }
        const newElement = {
          index: index,
          insert: element.insert,
          change: element.change,
          start: element.start,
          end: element.end,
          attributes: element.attributes,
          br: element.br,
          // secuence: element.secuenseValue,
        }
        this.newParagraphsList.push(newElement)
      }
      // console.log('selection processed', this.newParagraphsList);
      // console.log('selection processed', this.paragraphsList);
      this.textMenuAttributes = quillFunctions.GET_ATTRIBUTE_BY_RANGE(
        selectionToAdd.index,
        selectionToAdd.length
      );
      this.textMenuAttributes.list == "none" ||
      Array.isArray(this.textMenuAttributes.list)
        ? (this.btn_list_default = true)
        : (this.btn_list_default = false);
      // console.log("estilos", this.textMenuAttributes);
      return selectionToAdd;
    },
    setHeightText() {
      this.$store.dispatch("post/drag/GLOBAL_CHANGE_HEIGHT");
    },
    setFontFamily(e) {
      let range = this.quillGetSelection;
      let length_ = this.quillEditor.getLength();
      if (range == null) this.setFormatText(0, length_, "font", e.font);
      if (range !== null) {
        let { index, length } = range;
        if (index == 0 && length == 0)
          this.setFormatText(0, length_, "font", e.font);
        else this.setFormatText(index, length, "font", e.font);
      }
      this.fontFamilyValue = e.font;
      this.$store.dispatch("post/textControls/CHANGE_FONT_FAMILY_IN_TEXT", {
        fontFamily: e.font,
        uuid: this.selected,
        index: this.selectedText.key,
      });
      this.setHeightText();
    },
    setColor(e) {
      const selectionToAdd = this.returnSelectionRange();
      this.setFormatText(
        selectionToAdd.index,
        selectionToAdd.length,
        "color",
        e
      );
      this.$store.dispatch("post/textControls/CHANGE_COLOR_IN_TEXT", {
        color: e,
        uuid: this.selected,
        index: this.selectedText.key,
      });
      this.$store.dispatch("post/image/UPDATE_IMAGE_SELECTED", this.selected);
    },
    setFontSize(e, type) {
      if(typeof e != 'object') this.fontSizeValue = parseInt(e)
      let selectionToAdd = this.returnSelectionRange();
      let fontSizeItems = this.selectedDetail.styles.fontsList;
      const deltasRanges = this.paragraphsList;
      let deltas = this.quillEditor.getContents({
        index: 0,
        length: this.quillEditor.getLength(),
      }).ops;
      let newRangeToAdd = null;
      // this.fontSizeValue = valueGet;
      let currentFontItem = 0;
      let baseFontItem = 0;
      let paragraphList = this.quillEditor.container.firstChild.childNodes;
      if (deltasRanges) {
        const parentItemIndex = this.allImages.findIndex(({ uuid }) => uuid == this.selected);
        let editElements = deltasRanges.filter((e) => e.change == true);
        let noEditElements = deltasRanges.filter((e) => e.change == false);
        let parr_ = document.querySelectorAll(`[data-paragraph-id]`);
        this.$store.dispatch("post/drag/ADD_ID_QUILL_CONTENT", {
          paragraphList,
          parentItemIndex,
        });
        // console.log('inicial', fontSizeItems);
        paragraphList.forEach((item, index) => {
          let getDataId = item.getAttribute("data-paragraph-id");
          let getDataFontsize = item.getAttribute("data-font-size");
          let getDataIndex = parseFloat(
            item.getAttribute("data-paragraph-index")
          );
          let image_index = `p-${parentItemIndex}-${index}`;
          let currentFont = 0;
          let baseFont = 0;
          editElements.map((el) => {
            // console.log('editable', el);
            switch (item.tagName) {
              case "P":
                if (getDataIndex == el.index) {
                  baseFont = parseFloat(this.fontSizeValue);
                  currentFont = parseFloat(getDataFontsize);
                  innerAddFont(item, el, currentFont, this, e, type);
                }
                break;
              case "OL":
                // console.log('ol', item);
                item.childNodes.forEach((element, index) => {
                  let elID = element.getAttribute("data-paragraph-index");
                  let elFontSize = element.getAttribute("data-font-size");
                  if (elID == el.index) {
                    currentFont = parseFloat(elFontSize);
                    innerAddFont(element, el, currentFont, this, e, type);
                  }
                });
                break;
              default:
                if (getDataIndex == el.index) {
                  baseFont = parseFloat(this.fontSizeValue);
                  currentFont = parseFloat(getDataFontsize);
                  innerAddFont(item, el, currentFont, this, e, type);
                }
                break;
            }
          });
          noEditElements.map((e) => {
            // console.log('no editable', e);
            switch (item.tagName) {
              case "P":
                if (getDataIndex == e.index) {
                  innerAddSize(item,e);
                }
                break;
              case "OL":
                item.childNodes.forEach((element, index) => {
                  let elID = element.getAttribute("data-paragraph-index");
                  if (elID == e.index) {
                    innerAddSize(element,e);
                  }
                });
                break;
              default:
                if (getDataIndex == e.index) {
                  innerAddSize(item,e);
                }
                break;
            }
          });
        });
      }
      function innerAddSize(item,e) {
        const fontSizeFilter = fontSizeItems.find((el)=> el.index == e.index);
        const defaulFontSize = fontSizeFilter.font
        // console.log('Lista de parrafos no editables',item,fontSizeFilter);
        item.childNodes.forEach((it) => {
          if (it.nodeType === Node.ELEMENT_NODE) {
            it.style.fontSize = defaulFontSize;
          }
        });
      }
      function innerAddFont(element, el, currentFont, this_, e, type) {
        const fontSizeFilter = fontSizeItems.find((f)=> f.index == el.index);
        let font_ = 0;
        let isArray = Array.isArray(el.attributes.size);
        let fontSizeAttribute = fontSizeFilter.font.replace("px", "");
        // console.log('elemento editable-----', element, currentFont,font_,fontSizeFilter.font, fontSizeAttribute);
        font_ = parseFloat(fontSizeAttribute);
        switch (type) {
          case "up":
            currentFont = font_ + 1;
            break;
          case "down":
            currentFont = font_ - 1;
            break;
          case undefined:
            if(parseInt(e) >= 0) currentFont = parseInt(e);
            break;
        }
        if(currentFont >= 0){

          this_.fontSizeValue = currentFont;
          element.removeAttribute("data-font-size");
          element.setAttribute("data-font-size", currentFont);
          // let text___ = this.quillEditor.getText(index, length);
          this_.$store.dispatch("post/drag/SET_GLOBAL_FONT_SIZE", {
            fontSize: currentFont,
            range: { index: el.start, length: el.end },
          });
          // console.log('add fontsize--3', fontSizeItems);
          fontSizeItems.map((f)=>{
            if(f.index == el.index){
              f.font = `${currentFont}px`
            }
            
          })
        }
        // console.log('add fontsize--3', currentFont, element, el, fontSizeItems);
        // this_.$store.dispatch('post/drag/ADD_ID_QUILL_CONTENT', {paragraphList,parentItemIndex});
      }
      this.$store.dispatch("post/textControls/CHANGE_FONT_SIZE_IN_TEXT", {
        fontSize: fontSizeItems,
        uuid: this.selected,
        index: this.selectedText.key,
      });
      // console.log('final', this.selectedDetail);
      this.setHeightText();
    },
    setFontWeight() {
      // this.setTypeFormat('bold');
      let selectionToAdd = this.returnSelectionRange();
      let formato = this.quillEditor.getFormat({
        index: selectionToAdd.index,
        length: selectionToAdd.length,
      });
      if (formato.bold == undefined)
        this.setFormatText(
          selectionToAdd.index,
          selectionToAdd.length,
          "bold",
          true
        );
      if (formato.bold == true)
        this.setFormatText(
          selectionToAdd.index,
          selectionToAdd.length,
          "bold",
          false
        );
      if (formato.bold == false)
        this.setFormatText(
          selectionToAdd.index,
          selectionToAdd.length,
          "bold",
          true
        );
      console.log("rango bold", selectionToAdd, formato);

      this.$store.dispatch("post/textControls/ADD_FONT_WEIGHT_IN_TEXT", {
        uuid: this.selected,
        index: this.selectedText.key,
      });
      this.setHeightText();
    },
    setTypeFormat(type, value) {
      // console.log('set',type,value);
      let range = this.quillGetSelection;
      let length_ = this.quillEditor.getLength();
      let formato = null;
      if (type == "align") {
        this.setFormatText(0, length_, type, value);
        if (value == "left") this.setFormatText(0, length_, type, "");
      } else {
        if (range == null) {
          formato = this.quillEditor.getFormat({ index: 0, length: length_ });
          this.setFormatText(0, length_, type, true);
        } else {
          let { index, length } = range;
          if (length == 0 && index == 0) {
            formato = this.quillEditor.getFormat({ index: 0, length: length_ });
            if (formato[type] == undefined)
              this.setFormatText(0, length_, type, true);
            else this.setFormatText(0, length_, type, false);
          } else {
            formato = this.quillEditor.getFormat(range);
            if (formato[type]) this.setFormatText(index, length, type, false);
            else this.setFormatText(index, length, type, true);
          }
        }
      }
    },
    setFormatText(index, length, type, value) {
      this.quillEditor.formatText(index, length, type, value);
    },
    setFontAlign(textAlign) {
      this.setTypeFormat("align", textAlign);
      this.$store.dispatch("post/textControls/ADD_FONT_ALIGN_IN_TEXT", {
        textAlign,
        uuid: this.selected,
        index: this.selectedText.key,
      });
    },
    updateTextSelected(selected) {
      const children = selected.baseNode.childNodes;

      for (var i = 0; i < children.length; i++) {
        if (children[i].nodeName === "#text") {
          console.log(children[i].nodeValue);
        } else if (children[i].nodeName === "SPAN") {
          console.log(children[i].innerHTML);
        }
      }

      // children.removeChild(0)
    },
    formatFromSelection(i, l) {
      return this.quillEditor.getFormat(i, l);
    },
    setFontTransform() {
      let range = this.quillGetSelection;
      let length_ = this.quillEditor.getLength();
      let resl = null;
      // let selectedText_quill = null;
      if (range == null) {
        resl = this.formatFromSelection(0, length_);
        this.quillEditor.formatText(0, length_, "line", "uppercase");
      } else {
        let { index, length } = range;
        if (index == 0 && length == 0) {
          // if(!resl.textTransform)
          resl = this.formatFromSelection(0, length_);
          if (resl.textTransform == undefined)
            this.quillEditor.formatText(0, length_, "line", "uppercase");
          if (resl.textTransform == "uppercase")
            this.quillEditor.formatText(0, length_, "line", "lowercase");
          if (resl.textTransform == "lowercase")
            this.quillEditor.formatText(0, length_, "line", "uppercase");
          console.log("mayusculas ---1", resl);
        } else {
          resl = this.formatFromSelection(index, length);
          console.log("mayusculas ---2", resl.textTransform);
          if (resl.textTransform == undefined)
            this.quillEditor.formatText(index, length, "line", "uppercase");
          if (resl.textTransform == "uppercase")
            this.quillEditor.formatText(index, length, "line", "lowercase");
          if (resl.textTransform == "lowercase")
            this.quillEditor.formatText(index, length, "line", "uppercase");
          if (resl.textTransform && resl.textTransform.length == 2)
            this.quillEditor.formatText(index, length, "line", "uppercase");
        }
      }
      this.$store.dispatch("post/textControls/ADD_FONT_TRANSFORM_IN_TEXT", {
        uuid: this.selected,
        index: this.selectedText.key,
      });
      this.setHeightText();
    },
    setFontCursive() {
      this.setTypeFormat("italic");
      this.$store.dispatch("post/textControls/ADD_ITALIC_IN_TEXT", {
        uuid: this.selected,
        index: this.selectedText.key,
      });
      this.setHeightText();
    },
    setFontUnderline() {
      this.setTypeFormat("underline");
      this.$store.dispatch("post/textControls/ADD_UNDERLINE_IN_TEXT", {
        uuid: this.selected,
        index: this.selectedText.key,
      });
      this.setHeightText();
    },
    setInterHight(lineHeight) {
      let range = this.quillGetSelection;
      let length_ = this.quillEditor.getLength();
      if (range == null) {
        this.quillEditor.formatText(0, length_, "lineHeight", lineHeight);
      } else {
        let { index, length } = range;
        index == 0 && length == 0
          ? this.quillEditor.formatText(0, length_, "lineHeight", lineHeight)
          : this.quillEditor.formatText(
              index,
              length,
              "lineHeight",
              lineHeight
            );
      }
      this.$store.dispatch("post/textControls/ADD_LINE_HEIGHT_IN_TEXT", {
        lineHeight,
        uuid: this.selected,
      });
      this.setHeightText();
    },
    getSelInterHeight(lineHeight) {
      const select = this.allImages.find(({ uuid }) => uuid == this.selected);
      return select.styles.lineHeight
        ? typeof select.styles.lineHeight == "number"
          ? lineHeight == select.styles.lineHeight
          : lineHeight == 1
        : false;
    },
    setListStyle() {
      switch (this.textMenuAttributes.list) {
        case "none":
          this.quillEditor.format("list", "bullet");
          break;
        case "bullet":
          this.quillEditor.format("list", "ordered");
          break;
        case "ordered":
          this.quillEditor.format("list", false);
          break;
        default:
          this.quillEditor.format("list", false);
          break;
      }
      let paragraphList = this.quillEditor.container.firstChild.childNodes;
      const parentItemIndex = this.allImages.findIndex(
        ({ uuid }) => uuid == this.selected
      );
      this.$store.dispatch("post/drag/ADD_ID_QUILL_CONTENT", {
        paragraphList,
        parentItemIndex,
      });
      this.setHeightText();
    },
  },
};
</script>
<style scoped>
.opacity__add {
  opacity: 0.4 !important;
}
.opacity__add i {
  color: #2196f3 !important;
}
.upp_bt,
.low_bt {
  width: 15px;
}
.low_bt i {
  position: relative;
  left: -15px;
}
.btn_fontSizes_action {
  position: absolute;
  right: 7px;
  top: -3px;
}
.btn_fontSizes_action button {
  height: 15px;
}
.button-color {
  height: 20px;
  width: 20px;
  border-radius: 2px;
  cursor: pointer;
}
.list-interhight {
  min-height: 30px !important;
  color: white !important;
  font-weight: bold !important;
  cursor: pointer;
}
.relative {
  position: relative;
}
.no-fontsize-value {
  position: absolute;
  top: 6px;
  left: 7px;
  background-color: white;
  width: 50px;
  padding-left: 5px;
}
</style>
