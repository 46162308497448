<template>
  <div class="custom-select" :tabindex="tabindex">
    <input
      class="selected"
      type="number"
      name="age"
      id="age"
      v-model="model"
      min="-500"
      @change="$emit('inputsize', model)"
      @input="$emit('inputsize', model)"
      @keydown="preventNegativeNumbers($event,model)"
      :disabled="showFontSizeText"
    />
  </div>
</template>

<script>
export default {
  props: {
    default: {
      type: Number,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    model: {
      get() {
        return this.default;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    showFontSizeText() {
      return this.$store.getters["post/drag/showFontSizeText"];
    },
  },
  data() {
    return {
      open: false,
      age: 13,
    };
  },
  mounted() {
    this.$emit("inputsize", this.age);
  },
  methods:{
    preventNegativeNumbers(event, model) {
      if (event.key === "ArrowDown" && model <= 0) event.preventDefault();
    },
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 76px;
  text-align: left;
  outline: none;
  line-height: 47px;
  
}

.custom-select .selected {
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #c5d0e4;
  box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
  color: #000000de;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
input[type=number] { -moz-appearance:textfield; }

.custom-select .selected.open {
  border: 1px solid #c5d0e4;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 20px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: rgba(93, 107, 136, 1) transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #c5d0e4;
  border-left: 1px solid #c5d0e4;
  border-bottom: 1px solid #c5d0e4;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 1;
  font-size: 14px;
}

.custom-select .items div {
  color: rgb(0, 0, 0);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #d7f1ff;
}

.selectHide {
  display: none;
}
</style>
