<template>
  <div>
    <div class="margin-1rem">
      <div class="text margin-1rem">
        <p
          class="text__title"
          draggable
          @click="addItemText(textsInline.title)"
          :style="textsInline.title.content[0].stylesThumbnail"
        >
          Add Tittle
        </p>
        <p
          class="text__subtitle"
          @click="addItemText(textsInline.subtitle)"
          :style="textsInline.subtitle.content[0].stylesThumbnail"
        >
          Add Subtitle
        </p>
        <p
          class="text__solo"
          @click="addItemText(textsInline.simpleText)"
          :style="textsInline.simpleText.content[0].stylesThumbnail"
        >
          Add Text
        </p>
      </div>
      <div v-if="texts.length > 0" class="wrapper-text scroll-base" style="">
        <div class="items-content">
          <v-row
            class="row"
            dense
            style="align-items: center; margin: 0"
          >
            <div
              class="text-object"
              v-for="(text, index) in texts"
              :key="text.id"
              draggable
              @click="addItemText(newTextArray[index])"
              @dragstart="dragStartText($event, newTextArray[index])"
              @dragend="dragEndText"
            >
              <p
                class="words"
                v-for="item in text.value.content"
                :key="item.key"
                :style="item.stylesThumbnail"
              >
                {{ item.defaultThumbnail }}
              </p>
            </div>
          </v-row>
        </div>
      </div>
      <div v-else class="loader-container">
        <div v-if="activeScroll" class="loader-container__progress">
          <div class="loader-container__progress--text">Getting Text Templates</div>
          <v-progress-linear
            color="white"
            height="6"
            indeterminate
            rounded
          ></v-progress-linear>
        </div>
        <div v-else class="loader-container__progress">
          <div class="loader-container__progress--text">
            Sorry, we couldn't find any matching items
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "@design/styleguide";
export default {
  components: {},
  name: "PostText",
  props: {
    route: {
      type: String,
    },
    trigger: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      prevItemDrag: null,
      textsInline: {
        title: {
          template: false,
          height: 60,
          width: 230,
          content: [
            {
              defaultThumbnail: "Add Title",
              default:
                '<p class="ql-align-center"><span class="ql-font-Arial" style="font-size: 42px;">Add Title</span></p>',
              delta: [
                {
                  attributes: {
                    size: "42px",
                    font: "Arial",
                  },
                  insert: "Add Title",
                },
                {
                  attributes: {
                    align: "center",
                  },
                  insert: "\n",
                },
              ],
              stylesThumbnail: {
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "left",
                fontWeight: "bold",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#FFFFFF",
              },
              styles: {
                fontSize: "42px",
                lineHeight: "42px",
                textAlign: "left",
                fontWeight: "regular",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#000000FF",
              },
            },
          ],
        },
        subtitle: {
          height: 47,
          width: 190,
          template: false,
          content: [
            {
              defaultThumbnail: "Add subtitle",
              default:
                '<p class="ql-align-center"><span class="ql-font-Arial" style="font-size: 32px;">Add subtitle</span></p>',
              delta: [
                {
                  attributes: {
                    size: "32px",
                    font: "Arial",
                  },
                  insert: "Add subtitle",
                },
                {
                  attributes: {
                    align: "center",
                  },
                  insert: "\n",
                },
              ],
              stylesThumbnail: {
                fontSize: "17px",
                lineHeight: "17px",
                textAlign: "left",
                fontWeight: "",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#FFFFFF",
              },
              styles: {
                fontSize: "32px",
                lineHeight: "32px",
                textAlign: "left",
                fontWeight: "regular",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#000000FF",
              },
            },
          ],
        },
        simpleText: {
          height: 30,
          width: 120,
          template: false,
          content: [
            {
              defaultThumbnail: "Add Text",
              default:
                '<p class="ql-align-center"><span class="ql-font-Arial" style="font-size: 24px;">Add Text</span></p>',
              delta: [
                {
                  attributes: {
                    size: "24px",
                    font: "Arial",
                  },
                  insert: "Add Title",
                },
                {
                  attributes: {
                    align: "center",
                  },
                  insert: "\n",
                },
              ],
              stylesThumbnail: {
                fontSize: "12px",
                lineHeight: "12px",
                textAlign: "left",
                fontWeight: "",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#FFFFFF",
              },
              styles: {
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "left",
                fontWeight: "regular",
                textTransform: "",
                fontFamily: "Arial",
                fontStyle: "",
                color: "#000000FF",
              },
            },
          ],
        },
      },
      ediT: true,
      sizeAT: false,
      infos: false,
      showTexts: true,
      activeScroll: true,
      newTextArray : [
      {
        height: 224,
        width: 300,
        template: true,
        content: [
          {
            defaultThumbnail: "template 1",
            default:
              '<p data-font-size="55" data-font-family="&quot;BebasNeue&quot;" style="font-size: 55px; font-family: &quot;Bebas Neue&quot;, sans-serif;" data-paragraph-id="p-0-0" data-paragraph-index="0"><span class="ql-font-BebasNeue lineHeight-1-0" style="font-size: 55px;">LOREM IPSUM DOLOR SIT AME</span></p><p data-font-size="15" data-font-family="Arial" style="font-size: 15px; font-family: Arial;" data-paragraph-id="p-0-1" data-paragraph-index="1"><span class="ql-font-Arial" style="font-size: 15px;"> </span></p><p data-font-size="25" data-font-family="Arial" style="font-size: 25px; font-family: Arial;" data-paragraph-id="p-0-2" data-paragraph-index="2"><span class="ql-font-Arial lineHeight-1-0" style="font-size: 25px;">Maecenas maximus sapien vitae diam mollis tempus. Nam a dignis.</span></p>',
            delta: [
              {
                  "attributes": {
                      "size": "55px",
                      "font": "BebasNeue",
                      "textLineHeight": "1-0",
                  },
                  "insert": "LOREM IPSUM DOLOR SIT AME"
              },
              {
                  "attributes": {
                      "size": "55px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "15px"
                  },
                  "insert": " "
              },
              {
                  "attributes": {
                      "size": "15px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "25px",
                      "textLineHeight": "1-0"
                  },
                  "insert": "Maecenas maximus sapien vitae diam mollis tempus. Nam a dignis."
              },
              {
                  "attributes": {
                      "size": "25px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
              fontsList:[
              {
                  "index": 0,
                  "font": "55px",
                  "fontFamily": "BebasNeue"
              },
              {
                  "index": 1,
                  "font": "15px",
                  "fontFamily": "Arial"
              },
              {
                  "index": 2,
                  "font": "25px",
                  "fontFamily": "Arial"
              }
            ],
            },
          },
        ],
      },
      {
        height: 167,
        width: 238,
        template: true,
        content: [
          {
            defaultThumbnail: "template 2",
            default:
              '<p class="ql-align-center" data-font-size="40" data-font-family="&quot;LibreBaskerville&quot;" style="font-size: 40px; font-family: &quot;Libre Baskerville&quot;, sans-serif;" data-paragraph-id="p-3-0" data-paragraph-index="0"><span class="ql-font-Baskerville">EX EGET</span></p><p class="ql-align-center" data-font-size="15" data-font-family="&quot;LibreBaskerville&quot;" style="font-size: 15px; font-family: &quot;Libre Baskerville&quot;, sans-serif;" data-paragraph-id="p-3-1" data-paragraph-index="1"><span class="ql-font-Baskerville" style="font-size: 15px;"> </span></p><p class="ql-align-center" data-font-size="21" data-font-family="Arial" style="font-size: 21px; font-family: Arial;" data-paragraph-id="p-3-2" data-paragraph-index="2"><span class="ql-font-Arial lineHeight-1-0" style="font-size: 21px;">Macenas maximus sapien vitae diam mollis tempus. Nam a dignis Nam a dignis a dignis.</span></p>',
            delta: [
              {
                  "attributes": {
                      "font": "Baskerville"
                  },
                  "insert": "EX EGET"
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": ""
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Baskerville",
                      "size": "15px"
                  },
                  "insert": " "
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": "15px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "21px",
                      "textLineHeight": "1-0"
                  },
                  "insert": "Macenas maximus sapien vitae diam mollis tempus. Nam a dignis Nam a dignis a dignis."
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": "21px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontsList:[
                {
                    "index": 0,
                    "font": "40px",
                    "fontFamily": "Baskerville"
                },
                {
                    "index": 1,
                    "font": "15px",
                    "fontFamily": "Baskerville"
                },
                {
                    "index": 2,
                    "font": "21px",
                    "fontFamily": "Arial"
                }
              ],
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
            },
          },
        ],
      },
      {
        height: 210,
        width: 376,
        template: true,
        content: [
          {
            defaultThumbnail: "template 3",
            default:
              '<p class="ql-align-center" data-font-size="50" data-font-family="Economica" style="font-size: 50px; font-family: Economica, sans-serif;" data-paragraph-id="p-0-0" data-paragraph-index="0"><span class="ql-font-Economica lineHeight-1-0" style="font-size: 50px;">IN VEL LACUS CONDIMENTUM, TEMPUS MAURIS</span></p><p class="ql-align-center" data-font-size="15" style="font-size: 15px;" data-paragraph-id="p-0-1" data-paragraph-index="1"><br></p><p class="ql-align-center" data-font-size="20" data-font-family="Arial" style="font-size: 20px; font-family: Arial;" data-paragraph-id="p-0-2" data-paragraph-index="2"><span class="ql-font-Arial">Maecenas maximus sapien</span></p>',
            delta: [
              {
                  "attributes": {
                      "size": "50px",
                      "font": "Economica",
                      "textLineHeight": "1-0"
                  },
                  "insert": "IN VEL LACUS CONDIMENTUM, TEMPUS MAURIS"
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": "50px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": ""
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial"
                  },
                  "insert": "Maecenas maximus sapien"
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": ""
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontsList:[
                {
                    "index": 0,
                    "font": "50px",
                    "fontFamily": "Archivo"
                },
                {
                    "index": 1,
                    "font": "15px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 2,
                    "font": "20px",
                    "fontFamily": "Arial"
                }
              ],
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
            },
          },
        ],
      },
      {
        height: 181,
        width: 322,
        template: true,
        content: [
          {
            defaultThumbnail: "template 4",
            default:
              '<p class="ql-align-center" data-font-size="50" data-font-family="Arapey" style="font-size: 50px; font-family: Arapey, sans-serif;" data-paragraph-id="p-3-0" data-paragraph-index="0"><span class="ql-font-Arapey lineHeight-1-0" style="font-size: 50px;">PREASENT SCELER</span></p><p class="ql-align-center" data-font-family="Arial" style="font-size: 15px; font-family: Arial;" data-font-size="15" data-paragraph-id="p-3-1" data-paragraph-index="1"><span class="ql-font-Arial" style="font-size: 15px;"> </span></p><p class="ql-align-center" data-font-family="Arial" style="font-size: 18px; font-family: Arial;" data-font-size="18" data-paragraph-id="p-3-2" data-paragraph-index="2"><span class="ql-font-Arial" style="font-size: 19px;">DONECMAGNAORCI</span></p>',
            delta: [
              {
                  "attributes": {
                      "size": "50px",
                      "font": "Arapey",
                      "textLineHeight": "1-0"
                  },
                  "insert": "PREASENT SCELER"
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": "50px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "15px"
                  },
                  "insert": " "
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": "15px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "19px"
                  },
                  "insert": "DONECMAGNAORCI"
              },
              {
                  "attributes": {
                      "align": "center",
                      "size": "18px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
              fontsList: [
                {
                    "index": 0,
                    "font": "50px",
                    "fontFamily": "Arapey"
                },
                {
                    "index": 1,
                    "font": "15px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 2,
                    "font": "19px",
                    "fontFamily": "Arial"
                }
              ]
            },
          },
        ],
      },
      {
        height: 302,
        width: 305,
        template: true,
        content: [
          {
            defaultThumbnail: "template 5",
            default:
              '<p data-font-family="&quot;SeaweedScript&quot;" style="font-size: 64px; font-family: &quot;Seaweed Script&quot;, sans-serif;" data-font-size="64" data-paragraph-id="p-0-0" data-paragraph-index="0"><span class="ql-font-SeaweedScript lineHeight-1-0" style="font-size: 65px;">Loren ipsum dolor sit ame</span></p><p data-font-size="15" style="font-size: 15px;" data-paragraph-id="p-0-1" data-paragraph-index="1"><br style="font-size: 15px;"></p><p data-font-family="Arial" style="font-size: 25px; font-family: Arial;" data-font-size="25" data-paragraph-id="p-0-2" data-paragraph-index="2"><span class="ql-font-Arial" style="font-size: 25px;">Maecenas maximus </span></p><p data-font-size="25" data-font-family="Arial" style="font-size: 25px; font-family: Arial;" data-paragraph-id="p-0-3" data-paragraph-index="3"><span class="ql-font-Arial" style="font-size: 25px;">sapien vitae diam mollis</span></p>',
            delta: [
              {
                  "attributes": {
                      "size": "65px",
                      "font": "SeaweedScript",
                      "textLineHeight": "1-0"
                  },
                  "insert": "Loren ipsum dolor sit ame"
              },
              {
                  "attributes": {
                      "size": "64px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "size": "15px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "25px"
                  },
                  "insert": "Maecenas maximus "
              },
              {
                  "attributes": {
                      "size": "25px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "25px"
                  },
                  "insert": "sapien vitae diam mollis"
              },
              {
                  "attributes": {
                      "size": "25px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
              fontsList: [
                {
                    "index": 0,
                    "font": "65px",
                    "fontFamily": "Bilbo"
                },
                {
                    "index": 1,
                    "font": "15px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 2,
                    "font": "25px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 3,
                    "font": "25px",
                    "fontFamily": "Arial"
                }
              ]
            },
          },
        ],
      },
      {
        height: 220,
        width: 264,
        template: true,
        content: [
          {
            defaultThumbnail: "template 6",
            default:
              '<p data-font-size="25" data-font-family="Roboto" style="font-size: 25px; font-family: Roboto, sans-serif;" data-paragraph-id="p-0-0" data-paragraph-index="0"><strong class="ql-font-Roboto" style="font-size: 25px;">AENEA                 GRAV</strong></p><p data-font-size="23" data-font-family="Roboto" style="font-size: 23px; font-family: Roboto, sans-serif;" data-paragraph-id="p-0-1" data-paragraph-index="1"><span class="ql-font-Roboto lineHeight-2-0" style="font-size: 23px;">Sapien a commodo 89</span></p><p data-font-size="23" data-font-family="Roboto" style="font-size: 23px; font-family: Roboto, sans-serif;" data-paragraph-id="p-0-2" data-paragraph-index="2"><span class="ql-font-Roboto lineHeight-2-0" style="font-size: 23px;">tellus arcu mollis 78</span></p><p data-font-size="23" data-font-family="Roboto" style="font-size: 23px; font-family: Roboto, sans-serif;" data-paragraph-id="p-0-3" data-paragraph-index="3"><span class="ql-font-Roboto lineHeight-2-0" style="font-size: 23px;">Sapi</span><span class="ql-font-Roboto" style="font-size: 23px;">en a commodo 85</span></p><p data-font-size="23" data-font-family="Roboto" style="font-size: 23px; font-family: Roboto, sans-serif;" data-paragraph-id="p-0-4" data-paragraph-index="4"><span class="ql-font-Roboto lineHeight-2-0" style="font-size: 23px;">tellus arcu mollis 78</span></p>',
            delta: [
              {
                  "attributes": {
                      "size": "25px",
                      "font": "Roboto",
                      "bold": true
                  },
                  "insert": "AENEA                 GRAV"
              },
              {
                  "attributes": {
                      "size": "25px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Roboto",
                      "size": "23px",
                      "textLineHeight": "2-0"
                  },
                  "insert": "Sapien a commodo 89"
              },
              {
                  "attributes": {
                      "size": "23px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Roboto",
                      "size": "23px",
                      "textLineHeight": "2-0"
                  },
                  "insert": "tellus arcu mollis 78"
              },
              {
                  "attributes": {
                      "size": "23px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Roboto",
                      "size": "23px",
                      "textLineHeight": "2-0"
                  },
                  "insert": "Sapi"
              },
              {
                  "attributes": {
                      "font": "Roboto",
                      "size": "23px"
                  },
                  "insert": "en a commodo 85"
              },
              {
                  "attributes": {
                      "size": "23px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Roboto",
                      "size": "23px",
                      "textLineHeight": "2-0"
                  },
                  "insert": "tellus arcu mollis 78"
              },
              {
                  "attributes": {
                      "size": "23px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
              fontsList:[
                {
                    "index": 0,
                    "font": "25px",
                    "fontFamily": "Archivo"
                },
                {
                    "index": 1,
                    "font": "23px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 2,
                    "font": "23px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 3,
                    "font": "23px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 4,
                    "font": "23px",
                    "fontFamily": "Arial"
                }
              ]
            },
          },
        ],
      },
      {
        height: 192,
        width: 286,
        template: true,
        content: [
          {
            defaultThumbnail: "template 7",
            default:
              '<p data-font-size="20" data-font-family="Arial" style="font-size: 20px; font-family: Arial;" data-paragraph-id="p-0-0" data-paragraph-index="0"><span class="ql-font-Arial lineHeight-1-0" style="font-size: 20px;">Donec magna orci</span></p><p data-font-size="50" data-font-family="Phosphate" style="font-size: 50px; font-family: Phosphate;" data-paragraph-id="p-0-1" data-paragraph-index="1"><span class="ql-font-Phosphate lineHeight-1-0" style="font-size: 50px;">NULA AC</span></p><p data-font-size="15" style="font-size: 15px;" data-paragraph-id="p-0-2" data-paragraph-index="2"><br></p><p data-font-size="25" data-font-family="Arial" style="font-size: 25px; font-family: Arial;" data-paragraph-id="p-0-3" data-paragraph-index="3"><span class="ql-font-Arial" style="font-size: 25px;">Maecenas maximus sapien vitae diam mollis</span></p>',
            delta: [
              {
                  "attributes": {
                      "size": "20px",
                      "font": "Arial",
                      "textLineHeight": "1-0"
                  },
                  "insert": "Donec magna orci"
              },
              {
                  "attributes": {
                      "size": "20px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Phosphate",
                      "size": "50px",
                      "textLineHeight": "1-0"
                  },
                  "insert": "NULA AC"
              },
              {
                  "attributes": {
                      "size": "50px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "size": "15px"
                  },
                  "insert": "\n"
              },
              {
                  "attributes": {
                      "font": "Arial",
                      "size": "25px"
                  },
                  "insert": "Maecenas maximus sapien vitae diam mollis"
              },
              {
                  "attributes": {
                      "size": "25px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
              fontsList: [
                {
                    "index": 0,
                    "font": "20px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 1,
                    "font": "50px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 2,
                    "font": "15px",
                    "fontFamily": "Arial"
                },
                {
                    "index": 3,
                    "font": "25px",
                    "fontFamily": "Arial"
                }
              ]
            },
          },
        ],
      },
      {
        height: 274,
        template: true,
        width: 310,
        content: [
          {
            defaultThumbnail: "template 8",
            default:
              '<p data-font-family="&quot;AbhayaLibre&quot;" style="font-size: 64px; font-family: &quot;Abhaya Libre&quot;;" data-font-size="64" data-paragraph-id="p-0-0" data-paragraph-index="0"><strong class="lineHeight-1-0 ql-font-AbhayaLibre" style="font-size: 65px;">Loren ipsum dolor sit ame. </strong></p>',
            delta: [
              {
                  "attributes": {
                      "size": "65px",
                      "font": "AbhayaLibre",
                      "textLineHeight": "1-0",
                      "bold": true
                  },
                  "insert": "Loren ipsum dolor sit ame. "
              },
              {
                  "attributes": {
                      "size": "64px"
                  },
                  "insert": "\n"
              }
            ],
            stylesThumbnail: {
              fontSize: "24px",
              lineHeight: "24px",
              textAlign: "left",
              fontWeight: "bold",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#FFFFFF",
            },
            styles: {
              fontSize: "42px",
              lineHeight: "42px",
              textAlign: "left",
              fontWeight: "regular",
              textTransform: "",
              fontFamily: "Arial",
              fontStyle: "",
              color: "#000000FF",
              fontsList: [
                {
                    "index": 0,
                    "font": "65px",
                    "fontFamily": "AbhayaLibre"
                }
              ]
            },
          },
        ],
      },
    ]
    };
  },
  computed: {
    groups() {
      return this.$store.getters["post/texts/find"];
    },
    elements() {
      return this.$store.getters["post/canvas/read"];
    },
    zoom() {
      return this.$store.getters["post/zoom/post"];
    },

    texts() {
      return this.$store.getters["post/texts/texts"];
    },
  },
  watch: {
    trigger(value) {
      if (value) this.activeScroll = true;
      else {
        //this.$store.commit("post/images/RESET_IMAGES_BY_KEYWORDS");
        this.activeScroll = false;
      }
      /* this.addImages = [];
      this.firstAmountOfImages = 0;
      this.page = 1; */
    },
  },
  mounted() {
    this.$store.dispatch("post/texts/FIND");
  },
  methods: {
    ...mapActions({
      set: "post/canvas/SET",
    }),
    dragStartText(event,text){
      this.prevItemDrag = text;
    },
    dragEndText(){
      this.addItemText(this.prevItemDrag);
      this.prevItemDrag = null;
    },
    addItemText(item) {
      for (const text of item.content) {
        text.rotate = 0;
        text.x = this.zoom.width / 2;
        text.y = this.zoom.height / 2;
        text.height = item.height;
        text.width = item.width;
        text.isTemplate = item.template;
        this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", text);
      }
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    addSelectedText(item) {
      const x = this.zoom.width / 2;
      let y = this.zoom.height / 2;
      for (const text of item.content) {
        text.rotate = 0;
        text.x = x;
        text.y = y;
        y += text.height;
        this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", text);
      }
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    addText(item) {
      item.width = this.zoom.width - 40;
      // item.y = this.zoom.height / 2
      this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.lienzo_wrap .controles {
  position: fixed;
  border: 2px solid #00a5ff;
  z-index: 1;
  visibility: hidden;
}
.vdr {
  touch-action: none;
  position: absolute;
  box-sizing: border-box;
  border: 2px solid #00a5ff;
  cursor: move;
}
.vdr.inactive {
  border: none !important;
}
.vdr.active::before {
  outline: none !important;
}
#tool .ql-toolbar {
  position: absolute;
  top: 10px;
  width: 600px;
  z-index: 0;
  background: #fff;
}
.ql-toolbar {
  z-index: 600 !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
  display: none;
  z-index: 0;
}
.ql-container.ql-snow {
  /* border:none !important; */
  height: auto !important;
  min-height: auto;
  /* min-height: 65px; */
  border: none !important;
}
.text {
  color: #ffffff;
  &__title {
    cursor: pointer;
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
  }
  &__subtitle {
    cursor: pointer;
    font-size: 17px;
    line-height: 20px;
  }
  &__solo {
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
  }
}

.wrapper-text {
  height: 70vh !important;
  overflow: scroll;
}
.items-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.text-object {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.7rem !important;
  width: 148px;
  height: 148px;
  background-color: #273444;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  margin: 5px;
  padding: 10px;
  .words {
    padding: 0;
    margin: 0;
  }
}
</style>
